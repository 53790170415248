import { Worksheet } from "exceljs";
import { config } from "./config-climate";

const ROWNUM: any = {
	3: { drought: {}, wet: {}, winter: {}, boreal_summer_wet: {}, heat_wave: {}, wind: {}, sea_level_rise: {} },
	4: { drought: {}, wet: {}, winter: {}, boreal_summer_wet: {}, heat_wave: {}, wind: {}, sea_level_rise: {} },
	5: { drought: {}, wet: {}, winter: {}, boreal_summer_wet: {}, heat_wave: {}, wind: {}, sea_level_rise: {} },
};

export const resetRowNum = () => {
	Object.keys(ROWNUM).forEach((r) => {
		Object.keys(ROWNUM[r]).forEach((c) => {
			ROWNUM[r][c] = {};
		});
	});
};

export const climateUtil = (sheet: number, index: string, bandGroups: any, worksheet: Worksheet) => {
	console.log("climateUtil", sheet, index, bandGroups.length);

	const climateConfig = config[index];

	console.log(climateConfig);

	const rowNum = ROWNUM[sheet][index];

	console.log(rowNum);

	if (!climateConfig) {
		return;
	}

	bandGroups.forEach((bandGroup: any) => {
		if (!bandGroup) {
			return;
		}

		const id = bandGroup.id;
		const bands = bandGroup.bands;

		if (!id || !bands || bands.length === 0) {
			return;
		}

		bands.forEach((band: any) => {
			if (!band) {
				return;
			}

			const name = band.name;
			const value = band.value;

			console.log(name, value);

			if (!name || !value) {
				return;
			}

			if (value === "NaN" || value === "undefined" || value === "null") {
				return;
			}

			const valueInt = Math.round(value * 100) / 100;

			const col = climateConfig[name];

			console.log(col);

			if (!col) {
				return;
			}

			let row = 2;
			if (!rowNum[col]) {
				rowNum[col] = 2;
			} else {
				row = rowNum[col] + 1;
				rowNum[col] = row;
			}

			console.log(row);

			const cellInt = worksheet.getCell(`${col}${row}`);

			cellInt.value = valueInt;
		});
	});

	console.log(ROWNUM);
};
