import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { ReactComponent as ExportListSvg } from "assets/exportList.svg";
import { ReactComponent as EmptyIcon } from "assets/icon_location_legend.svg";
import { ReactComponent as NarrowSvg } from "assets/narrow.svg";
import { ReactComponent as SpreadSvg } from "assets/spread.svg";
import Icon from "components/icon/icon";
import Input from "components/input/input";
import { LeftPanel, RightPanel, zoomLevelMedium } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { LocationData } from "helpers/models";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import styles from "./location-set.module.scss";

function LocationSet() {
	const [context, dispatch] = useContext(AppContext);
	const [selected, setSelected] = useState<string>("");
	const [rowCount, setRowCount] = useState(context.currentMultipleLocations?.items?.filter((location: LocationData) => location.checked).length || 0);
	const [multipleLocationsSearch, setMultipleLocationsSearch] = useState("");
	const [t] = useTranslation();
	const [data, setData] = useState<any[]>(context.currentMultipleLocations?.items || []);
	const [active, setActive] = useState<"code" | "name">("code");
	const [direction, setDirection] = useState<{ code: "asc" | "desc", name: "asc" | "desc" }>({ code: "asc", name: "asc" });

	const handleSort = (property: "code" | "name") => {
		if (!data) {
			return;
		}

		if (!property) {
			return;
		}

		setActive(property);

		direction[property] = direction[property] === "asc" ? "desc" : "asc";
		setDirection(direction);

		let arr = [...context.currentMultipleLocations?.items!]
		arr.sort((a: any, b: any) => {
			if (!a || !b) {
				return 0;
			}

			const aValue = a?.[property];
			const bValue = b?.[property];

			if (aValue === undefined) {
				return direction[property] === "asc" ? -1 : 1;
			}
			if (bValue === undefined) {
				return direction[property] === "asc" ? 1 : -1;
			}


			if (aValue < bValue) {
				return direction[property] === "asc" ? 1 : -1;
			}
			if (aValue > bValue) {
				return direction[property] === "asc" ? -1 : 1;
			}

			return 0;
		});

		setData([...arr]);
	};

	useEffect(() => {
		if (context.currentMultipleLocations!.items && context.currentMultipleLocations!.items.length > 0) {

			let arr = [...context.currentMultipleLocations!.items!]
			console.log(context.currentMultipleLocations,'1');

			arr.sort((a: any, b: any) => {
				if (!a || !b) {
					return 0;
				}

				const aValue = a?.[active];
				const bValue = b?.[active];

				if (aValue === undefined) {
					return direction[active] === "asc" ? -1 : 1;
				}
				if (bValue === undefined) {
					return direction[active] === "asc" ? 1 : -1;
				}


				if (aValue < bValue) {
					return direction[active] === "asc" ? 1 : -1;
				}
				if (aValue > bValue) {
					return direction[active] === "asc" ? -1 : 1;
				}

				return 0;
			});

			setData([...arr]);
			setRowCount(arr.filter((location: LocationData) => location.checked).length);
		}else {
			setData([]);
			setRowCount(0);
		}
	}, [context.currentMultipleLocations!.items]);

	useEffect(() => {
		if (context.rightPanel === RightPanel.Multiple) {
			setSelected("");
		}
	}, [context.rightPanel]);

	const closeEdit = (): void => {
		dispatch({ type: AppAction.setMultipleLocationsEdit, payload: false });
	};

	const focusLocation = (location: LocationData): void => {
		if (context.currentMultipleLocationInfo?.id === location.id) {
			dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
			dispatch({ type: AppAction.setCurrentMultipleLocationInfo, payload: undefined });
		} else {
			dispatch({ type: AppAction.setCurrentMultipleLocationInfo, payload: location });
			dispatch({ type: AppAction.setMapZoom, payload: zoomLevelMedium });
			dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Single });
		}
		// if (location.id !== selected) {
		//   setSelected(location.id);
		//   dispatch({
		//     type: AppAction.setMapCenter,
		//     payload: [location.lon, location.lat],
		//   });
		//   dispatch({ type: AppAction.setMapZoom, payload: zoomLevelMedium });
		//   // dispatch({ type: AppAction.setSingleLocation, payload: location });
		//   dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Single });
		// } else {
		//   setSelected("");
		//   dispatch({ type: AppAction.setMapCenter, payload: undefined });
		//   dispatch({ type: AppAction.setMapZoom, payload: zoomLevelDefault });
		//   dispatch({ type: AppAction.setSingleLocation, payload: undefined });
		//   dispatch({ type: AppAction.setRightPanel, payload: RightPanel.Multiple });
		// }
	};

	const toggleLocation = (evt: any, location: any): void => {
		evt.stopPropagation();

		context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((item: LocationData) => {
			if (item.id === location.id) {
				item.checked = !item.checked;
			}
			return item;
		});
		let multipleLocations = context.multipleLocations?.map((locationSet: any) => {
			if (locationSet.id === context.currentMultipleLocations!.id) {
				locationSet.items = context.currentMultipleLocations!.items;
			}
			return locationSet;
		});
		let num: number = context.currentMultipleLocations!.items?.filter((location: LocationData) => location.checked).length || 0;
		setRowCount(num);
		dispatch({
			type: AppAction.setCurrentMultipleLocations,
			payload: context.currentMultipleLocations,
		});

		dispatch({
			type: AppAction.setMultipleLocations,
			payload: multipleLocations,
		});
	};

	const patchLocation = (item: any): void => {
		dispatch({
			type: AppAction.setEditMultipleLocation,
			payload: item,
		});

		dispatch({
			type: AppAction.setLeftPanel,
			payload: LeftPanel.EditLocation,
		});
	};


	const handleDeleteLocationSet = (location: any) => {
		dispatch({
			type: AppAction.setDeleteLocationModal,
			payload: location,
		});
	};

	const handleChangeLocationsSize = () => {
		dispatch({ type: AppAction.setLocationsSize, payload: !context.locationsSize });
	};

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		let locations = context.currentMultipleLocations!.items?.map((location: any) => {
			location.checked = event.target.checked;
			return location;
		});

		let multipleLocations = context.multipleLocations?.map((locationSet: any) => {
			if (locationSet.id === context.currentMultipleLocations!.id) {
				locationSet.items = context.currentMultipleLocations!.items;
			}
			return locationSet;
		});

		dispatch({
			type: AppAction.setMultipleLocations,
			payload: multipleLocations,
		});

		dispatch({
			type: AppAction.setCurrentMultipleLocations,
			payload: { ...context.currentMultipleLocations, items: locations },
		});

		if (event.target.checked) {
			setRowCount(context.currentMultipleLocations!.items!.length);
		} else {
			setRowCount(0);
		}
	};

	const exportLocationList = () => {
		if (!context.currentMultipleLocations) {
			return;
		}
		if (!context.currentMultipleLocations.items) {
			return;
		}

		const name = context.currentMultipleLocations.name;

		// const data1 = [[t("headerName"), name]];
		const data1 = [{ "": t("headerName"), " ": name }];
		const worksheet1 = XLSX.utils.json_to_sheet(data1);

		// if (worksheet1["!ref"]) {
		// 	const range = XLSX.utils.decode_range(worksheet1["!ref"]);
		// 	for (let col = range.s.c; col <= range.e.c; col++) {
		// 		worksheet1[XLSX.utils.encode_cell({ c: col, r: 0 })] = null;
		// 	}
		// }

		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet1, t("sheet.header"));

		const data: any[] = context.currentMultipleLocations.items.map((e) => {
			return {
				[t("importLocationSet.id")]: e.code,
				[t("importLocationSet.name")]: e.name,
				[t("importLocationSet.country")]: e.country,
				[t("importLocationSet.countryISO3")]: e.countryISO3,
				[t("importLocationSet.province")]: e.province,
				[t("importLocationSet.place")]: e.place,
				[t("importLocationSet.county")]: e.county,
				[t("importLocationSet.street")]: e.street,
				[t("importLocationSet.number")]: e.number,
				[t("importLocationSet.zipCode")]: e.zipCode,
				[t("importLocationSet.coordinateSource")]: e.coordinateSource,
				[t("importLocationSet.latitude")]: e.latitude,
				[t("importLocationSet.longitude")]: e.longitude,
			};
		});

		const worksheet = XLSX.utils.json_to_sheet(data);
		XLSX.utils.book_append_sheet(workbook, worksheet, t("sheet.site"));

		const fileName = name.replace(/\s+/g, "_");

		XLSX.writeFile(workbook, `${fileName}.xlsx`);
	};

	return (
		<div
			className={styles.set}
			style={{ width: context.locationsSize ? "800px" : "400px" }}
		>
			<div className={styles.header}>
				<div className={styles.title}>
					<Icon
						icon="arrow_back"
						onClick={closeEdit}
					/>
					<div className="flex items-center">
						<span> {context.currentMultipleLocations!.name}</span>
						<Icon
							icon="create"
							onClick={() => setLeftPanel(dispatch, LeftPanel.EditLocationSet)}
						/>
					</div>
					<div className={styles.close}>
						<Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
					</div>
				</div>
				{context.multipleLocations && (
					<div className="flex items-center justify-between mx-[10px] mb-[16px]">
						<div className={styles.search}>
							<Input
								icon="search"
								placeholder={t("locations.searchPlaceholder")}
								onChange={(e) => {
									setMultipleLocationsSearch(e.target.value);
								}}
								value={multipleLocationsSearch}
							/>
						</div>
						<div
							className={styles.switch}
							onClick={handleChangeLocationsSize}
						>
							{/* {context.locationsSize ? <NarrowSvg /> : <SpreadSvg />} */}
							{context.locationsSize ? (
								<div
									className="tooltip"
									data-tip={t("tooltip.collapse")}
								>
									<NarrowSvg />
								</div>
							) : (
								<div
									className="tooltip"
									data-tip={t("tooltip.expand")}
								>
									<SpreadSvg />
								</div>
							)}
						</div>
					</div>
				)}
			</div>
			{context.currentMultipleLocations!.items?.length ? (
				<Paper
					sx={{ width: "100%", overflow: "hidden", height: "556px", position: "relative", boxShadow: "none", flex: 1 }}
					className={styles.table}
				>
					<TableContainer sx={{ height: "100%", paddingBottom: "56px" }}>
						<Table
							stickyHeader
							aria-label="sticky  table"
							size="small"
						>
							<TableHead>
								<TableRow className={styles.row}>
									<TableCell
										style={{ padding: 0, width: 50 }}
										align="center"
									>
										<Checkbox
											color="success"
											indeterminate={rowCount > 0 && context.currentMultipleLocations!.items!.length !== rowCount}
											checked={rowCount > 0 && context.currentMultipleLocations!.items!.length === rowCount}
											onChange={handleSelectAllClick}
											inputProps={{
												"aria-label": "select all desserts",
											}}
										/>
									</TableCell>
									<TableCell
										align="left"
										style={{ width: 100 }}
									>
										<TableSortLabel
											active={active === "code"}
											onClick={() => handleSort("code")}
											direction={direction.code}
										>
											{t("locations.table.id")}
										</TableSortLabel>
									</TableCell>
									<TableCell align="left">
										<TableSortLabel
											active={active === "name"}
											onClick={() => handleSort("name")}
											direction={direction.name}
										>
											{t("locations.table.name")}
										</TableSortLabel>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data?.map(
									(location: LocationData, index: number) =>
										(multipleLocationsSearch === "" || location.name.toLowerCase().includes(multipleLocationsSearch.toLowerCase())) && (
											<TableRow
												key={location.id}
												selected={location.id === (context.currentMultipleLocationInfo ? context.currentMultipleLocationInfo.id : "")}
												className={styles.row}
												onClick={() => focusLocation(location)}
												sx={{
													"&.Mui-selected,&.Mui-selected&:hover": {
														backgroundColor: "rgba(14, 138, 63, 0.08)!important",
													},
													"&.Mui-selected .delete-set": {
														background: "linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #ecf6f0 24px)!important",
													},
												}}
											>
												<TableCell
													style={{ padding: 0 }}
													align="center"
												>
													<Checkbox
														color="success"
														checked={location.checked}
														onClick={(evt: any) => toggleLocation(evt, location)}
													/>
												</TableCell>
												<TableCell align="left">{location.code}</TableCell>
												<TableCell align="left">{location.name}</TableCell>
												<TableCell style={{ padding: 0 }}>
													<div className={styles.delete}>
														<div
															className={`${styles.icons} delete-set`}
															onClick={(evt) => evt.stopPropagation()}
														>
															<div
																className="tooltip tooltip-bottom"
																data-tip={t("tooltip.edit")}
															>
																<Icon
																	icon="create"
																	onClick={() => patchLocation(location)}
																/>
															</div>
															<div
																className="tooltip tooltip-bottom"
																data-tip={t("tooltip.delete")}
															>
																<Icon
																	icon="delete"
																	// onClick={() => deleteLocation(location, index)}
																	onClick={() => handleDeleteLocationSet(location)}
																/>
															</div>
														</div>
													</div>
												</TableCell>
											</TableRow>
										)
								)}
							</TableBody>
						</Table>
						<div
							className={styles.pagination}
							style={{ bottom: context.locationsSize ? "0" : "" }}
						>
							{t("locations.table.pagination")}
							{rowCount} {t("locations.table.of")} {context.currentMultipleLocations!.items!.length}
						</div>
					</TableContainer>
				</Paper>
			) : (
				<div className={styles.empty}>
					<EmptyIcon></EmptyIcon>
					<div className="my-[15px]">{t("locations.empty")}</div>
					<div>{t("locations.addEmpty")}</div>
				</div>
			)}

			<div className={styles.footer}>
				<Button
					variant="outlined"
					color="success"
					style={{ width: "100%" }}
					endIcon={<ExportListSvg style={{ width: "18px", height: "18px", fill: "green" }} />}
					onClick={exportLocationList}
				>
					{t("exportLocationList")}
				</Button>
			</div>
		</div>
	);
}

export default LocationSet;
