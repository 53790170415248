import Checkbox from "components/checkbox/checkbox";
import { RightPanel } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLegends, getAnalysis, getStartAnalysis, startMultipleAnalysis } from "api/layer";

import Button from "components/button/button";
import styles from "./select-layers.module.scss";

interface TypeButton {
	id: string;
	title: string;
	icon: React.FunctionComponent<any>;
}
interface Props {
	index: number;
	buttons: TypeButton[];
}
interface climateRiskScores {
	index: string;
	scenario?: string;
}

interface location {
	id: string;
	coordinates: {
		latitude: number;
		longitude: number;
	};
}


interface AnalysisProps {
	catNetLayers: undefined | string[];
	responseStructure: undefined | string[];
	climateRiskScores: undefined | climateRiskScores[];
	longitude?: number | string;
	latitude?: number | string;
	country?: string;
	oversea?: boolean;
	spatial?: string;
	locations?: location[];
}

function SelectLayers(props: Props) {
	const [context, dispatch] = useContext(AppContext);
	const [selectedButtons, setSelectedButtons] = useState<string[][]>([[], []]);
	const [t] = useTranslation();

	const close = (): void => {
		dispatch({ type: AppAction.setAnalyseModal, payload: false });
	};

	const runAnalysis = () => {
		// dispatch({ type: AppAction.setAnalysisData, payload: null });
		// dispatch({ type: AppAction.setBatchId, payload: undefined });
		if (context.rightPanel === RightPanel.Single) {
			let params: AnalysisProps = {
				catNetLayers: [],
				responseStructure: [],
				climateRiskScores: [],
				longitude: undefined,
				latitude: undefined,
				country: undefined,
				oversea: undefined,
				spatial: undefined,
			};
			if (context.currentMultipleLocationInfo) {
				params.longitude = context.currentMultipleLocationInfo.longitudeWgs;
				params.latitude = context.currentMultipleLocationInfo.latitudeWgs;
				params.country = context.currentMultipleLocationInfo.country;
				params.spatial = "wgs";

			} else {
				params.longitude = context.singleLocation!.lon;
				params.latitude = context.singleLocation!.lat;
				params.country = context.singleLocation!.country;
				params.oversea = context.singleLocation!.oversea;
				params.spatial = context.singleLocation!.spatial;
			}

			if (selectedButtons[0].length > 0) {
				params.catNetLayers = selectedButtons[0];
				dispatch({ type: AppAction.setAnalysisData, payload: { legendItems: "running", analysisItems: "running" } });
			}

			if (selectedButtons[1].length > 0) {
				params.climateRiskScores = selectedButtons[1].map((item) => {
					return { index: item };
				});
				params.responseStructure = ["{2030,2035,2040,2045,2050,2055,2060,2070,2085}"];
				dispatch({ type: AppAction.setBatchId, payload: "running" });
			}

			getStartAnalysis(params).then((resp: any) => {
				if (selectedButtons[0].length > 0) {
					const analysisItems = resp && resp.data && resp.data.catnet && resp.data.catnet.results;
					const sortArray = [
						"FL_Fluvial_Official",
						"FL_Fluvial_SwissRe",
						"FL_Surge_SwissRe",
						"FL_Pluvial_SwissRe",
						"EQ_LocalSoilCondition_Global_SwissRe",
						"EQ_Bedrock_Global_SwissRe",
						"EQ_Tsunami_SwissRe",
						"WS_Windspeed_Global_SwissRe",
						"CS_Hail_Global_SwissRe",
						"CS_Tornado_Global_SwissRe",
						"EQ_Landslide_Global_SwissRe",
						"WF_Wildfire_Global_SwissRe",
						"CS_Lightning_Global_SwissRe",
						"VO_AshThickness_Global_SwissRe",
						"WF_DistToBush_AUS_SwissRe",
						"DR_Subsidence_France_SwissRe",
					];

					analysisItems.sort((a: any, b: any) => {
						const indexA = sortArray.indexOf(a.layerId);
						const indexB = sortArray.indexOf(b.layerId);
						if (indexA === -1 || indexB === -1) {
							return 0;
						}
						return indexA - indexB;
					});

					let legends = context.hazardLegends && context.hazardLegends.filter((item) => {
						return selectedButtons[0].includes(item.layerId);
					});

					const analysisData = { legendItems: legends, analysisItems };
					dispatch({ type: AppAction.setAnalysisData, payload: analysisData });
				}

				if (selectedButtons[1].length > 0) {
					dispatch({ type: AppAction.setBatchId, payload: resp && resp.data && resp.data.crs && resp.data.crs.batchId });
				}
			});

			dispatch({ type: AppAction.setSingleLocationAnalysed, payload: true });
			dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: false });
		} else if (context.rightPanel === RightPanel.Multiple) {
			let params: AnalysisProps = {
				catNetLayers: [],
				responseStructure: [],
				climateRiskScores: [],
				locations: [],
			};
			if (selectedButtons[0].length > 0) {
				params.catNetLayers = selectedButtons[0];
				dispatch({
					type: AppAction.setHazardMultipleAnalysedLegends, payload: context.hazardLegends?.filter((item) => {
						return selectedButtons[0].includes(item.layerId);
					})
				});
			}

			if (selectedButtons[1].length > 0) {
				params.climateRiskScores = selectedButtons[1].map((item) => {
					return { index: item };
				});
				params.responseStructure = ["{2030,2035,2040,2045,2050,2055,2060,2070,2085}"];
				dispatch({
					type: AppAction.setClimateMultipleAnalysedLegends, payload: context.crsLayerList?.filter((item) => {
						return selectedButtons[1].includes(item.id);
					})
				});
			}

			context.currentMultipleLocations!.items!.forEach((item) => {
				if (item.checked) {
					params.locations!.push({
						id: item.id,
						coordinates: {
							latitude: item.latitudeWgs,
							longitude: item.longitudeWgs,
						},
					});
				}
			});

			if (JSON.stringify(params.locations) !== JSON.stringify(context.multipleAnalysedLocations || [])) {
				dispatch({ type: AppAction.setMultipleAnalysedLocations, payload: params.locations });
				dispatch({ type: AppAction.setHazardMultipleAnalysisResult, payload: undefined })
				dispatch({ type: AppAction.setClimateMultipleAnalysisResult, payload: undefined })
			} else {
				params.catNetLayers = params.catNetLayers?.filter((item) => {
					let isFind = context.hazardMultipleAnalysisResult?.find((result) => {
						return result.layerId === item
					});
					return !isFind;
				});
				params.climateRiskScores = params.climateRiskScores?.filter((item) => {
					if (context.climateMultipleAnalysisResult && context.climateMultipleAnalysisResult[0] && context.climateMultipleAnalysisResult[0].climateRiskScores) {
						let isFind = context.climateMultipleAnalysisResult[0].climateRiskScores.find((result) => {
							return result.index === item.index
						});
						return !isFind;
					} else {
						return true;
					}
				});
			}

			if (params.catNetLayers!.length > 0) {
				dispatch({ type: AppAction.setHazardMultipleAnalysisStatus, payload: "running" });
			} else {
				dispatch({ type: AppAction.setHazardMultipleAnalysisStatus, payload: undefined });
			}

			if (params.climateRiskScores!.length > 0) {
				dispatch({ type: AppAction.setClimateMultipleAnalysisStatus, payload: "running" });
			} else {
				dispatch({ type: AppAction.setClimateMultipleAnalysisStatus, payload: undefined });
			}

			if (params.catNetLayers!.length > 0 || params.climateRiskScores!.length > 0) {
				startMultipleAnalysis(params).then((res: any) => {
					if (res && res.code === 200) {
						dispatch({ type: AppAction.setJobId, payload: res && res.data && res.data.jobId });
					} else {
						if (params.catNetLayers!.length > 0) {
							dispatch({ type: AppAction.setHazardMultipleAnalysisStatus, payload: "error" });
						}
						if (params.climateRiskScores!.length > 0) {
							dispatch({ type: AppAction.setClimateMultipleAnalysisStatus, payload: "error" });
						}
					}
				});
			}
			dispatch({ type: AppAction.setSingleLocationAnalysed, payload: false });
			dispatch({ type: AppAction.setMultipleLocationsAnalysed, payload: true });
		}
		close();
	};

	const handleClick = (buttonTitle: string) => {
		const newSelectedButtons = [...selectedButtons[props.index]];
		const buttonIndex = newSelectedButtons.indexOf(buttonTitle);
		if (buttonIndex >= 0) {
			newSelectedButtons.splice(buttonIndex, 1);
		} else {
			newSelectedButtons.push(buttonTitle);
		}
		selectedButtons[props.index] = newSelectedButtons;
		setSelectedButtons([...selectedButtons]);
	};

	const handleSelectAll = (selectAll: boolean) => {
		const newSelectedButtons = !selectAll ? props.buttons.map((button) => button.id) : [];
		selectedButtons[props.index] = newSelectedButtons;
		setSelectedButtons([...selectedButtons]);
	};

	const handleRevert = () => {
		selectedButtons[props.index] = [];
		setSelectedButtons([...selectedButtons]);
	};

	const isAllSelected = selectedButtons[props.index].length === props.buttons.length;
	const isAnySelected = selectedButtons.flatMap((arr) => arr).length > 0;

	return (
		<div>
			<div className={styles.select}>
				<div className={styles.title}>{t(`analyseModal.${props.index === 0 ? "selectLayers" : "selects"}`)}</div>
				<div className={styles.checkbox}>
					<div>{t("analyseModal.buttons.select")}</div>
					<Checkbox
						onChange={() => { }}
						onClick={() => handleSelectAll(isAllSelected)}
						checked={isAllSelected}
					/>
				</div>
			</div>
			<div className={`grid grid-cols-2 gap-3.5 ${styles.perils}`}>
				{props.buttons.map((button: TypeButton) => (
					<div
						key={button.id}
						className={selectedButtons[props.index].includes(button.id) ? styles.activeButton : styles.button}
						onClick={() => handleClick(button.id)}
					>
						<div className={styles.icon}>
							<button.icon className={styles.image} />
						</div>
						{t("layers.item." + button.title)}
					</div>
				))}
			</div>
			<div className="flex justify-between mt-5 mb-5">
				<div>
					<Button
						className={`${isAnySelected ? styles.buttonRest : styles.buttonRestDisabled}`}
						onClick={handleRevert}
						type="secondary"
					>
						{t("analyseModal.buttons.reset")}
					</Button>
				</div>
				<div>
					<Button
						onClick={close}
						type="secondary"
						className={styles.closeButton}
					>
						{t("analyseModal.buttons.close")}
					</Button>
					<Button
						disabled={!isAnySelected}
						onClick={runAnalysis}
					>
						{t("analyseModal.buttons.run")}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default SelectLayers;
