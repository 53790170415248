import { getMultipleAnalysisResult, getMultipleAnalysisStatus } from "api/layer";
import Icon from "components/icon/icon";
import Options, { Option } from "components/options/options";
import { RightPanel } from "helpers/constants";
import { AppAction, AppContext } from "helpers/context";
import { getCheckedCounts } from "helpers/helpers";
import { ClimateItems } from "helpers/models";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClimateResults from ".//climate-results/climate-results";
import AnalysisResults from "./analysis-results/analysis-results";
import ClimateMultipleResults from "./multiple-results/climate-multiple-results/climate-multiple-results";
import HazardMultipleResults from "./multiple-results/hazard-multiple-results/hazard-multiple-results";

import styles from "./results.module.scss";

export function Results() {
	const [t] = useTranslation();
	const [context, dispatch] = useContext(AppContext);
	const [option, setOption] = useState<Option>(Option.HAZARD);
	const selectOption = (option: Option): void => {
		setOption(option);
		dispatch({ type: AppAction.setOption, payload: option });
	};

	useEffect(() => {
		if (context.rightPanel === RightPanel.Single && context.singleLocationAnalysed) {
			if (!context.analysisData) {
				setOption(Option.CLIMATE);
				dispatch({ type: AppAction.setOption, payload: Option.CLIMATE });
			} else if (!context.batchId) {
				setOption(Option.HAZARD);
				dispatch({ type: AppAction.setOption, payload: Option.HAZARD });
			}
		}
	}, [context.analysisData, context.batchId]);

	const getMultipleAnalysisData = async () => {
		if (!context.jobId || !context.multipleLocationsAnalysed) {
			return;
		}
		let res: any = await getMultipleAnalysisStatus(context.jobId);
		if (res && res.code !== 200) {
			if (context.hazardMultipleAnalysisStatus === "running") {
				dispatch({ type: AppAction.setHazardMultipleAnalysisStatus, payload: "error" });
			}
			if (context.climateMultipleAnalysisStatus === "running") {
				dispatch({ type: AppAction.setClimateMultipleAnalysisStatus, payload: "error" });
			}
			return;
		}
		res.data.forEach((item: any) => {
			if (item.isCompleted) {
				getMultipleAnalysisResultFn(item.tabId === "hazard" ? "1" : "4");
			} else {
				setTimeout(() => {
					resetMultipleAnalysisData(item.tabId);
				}, 3000);
			}
		});
	};

	const getMultipleAnalysisResultFn = async (tabId: string) => {
		let res: any = await getMultipleAnalysisResult(context.jobId!, tabId);
		if (res && res.code !== 200) {
			if (tabId === "1") {
				dispatch({ type: AppAction.setHazardMultipleAnalysisStatus, payload: "error" });
			} else {
				dispatch({ type: AppAction.setClimateMultipleAnalysisStatus, payload: "error" });
			}
			return;
		}
		if (tabId === "1") {
			dispatch({ type: AppAction.setHazardMultipleAnalysisStatus, payload: undefined });
			dispatch({ type: AppAction.setHazardMultipleAnalysisResult, payload: [...(context.hazardMultipleAnalysisResult || []), ...res.data.results] });
		} else {
			let resultData = res.data.results.map((item: ClimateItems) => {
				let obj = { ...item };
				context.climateMultipleAnalysisResult?.forEach((items) => {
					if (item.id === items.id) {
						obj.climateRiskScores = [...items.climateRiskScores, ...item.climateRiskScores];
					}
				});
				return obj;
			});
			dispatch({ type: AppAction.setClimateMultipleAnalysisStatus, payload: undefined });
			dispatch({ type: AppAction.setClimateMultipleAnalysisResult, payload: resultData });
		}
	};

	let requestNumber: any = {
		hazard: 0,
		climateChange: 0,
	};

	const resetMultipleAnalysisData = async (tabId: string) => {
		if (!context.jobId || !context.multipleLocationsAnalysed) {
			requestNumber[tabId] = 0;
			return;
		}
		let res: any = await getMultipleAnalysisStatus(context.jobId);
		if (res && res.code !== 200) {
			if (context.hazardMultipleAnalysisStatus === "running") {
				dispatch({ type: AppAction.setHazardMultipleAnalysisStatus, payload: "error" });
			}
			if (context.climateMultipleAnalysisStatus === "running") {
				dispatch({ type: AppAction.setClimateMultipleAnalysisStatus, payload: "error" });
			}
			return;
		}
		let status = res.data.find((item: any) => item.tabId === tabId);
		if (status && status.isCompleted) {
			requestNumber[tabId] = 0;
			getMultipleAnalysisResultFn(tabId === "hazard" ? "1" : "4");
		} else {
			setTimeout(() => {
				requestNumber[tabId]++;
				if (requestNumber[tabId] >= 9) {
					requestNumber[tabId] = 0;
					if (tabId === "hazard") {
						dispatch({ type: AppAction.setHazardMultipleAnalysisStatus, payload: "error" });
					} else {
						dispatch({ type: AppAction.setClimateMultipleAnalysisStatus, payload: "error" });
					}
					return;
				}
				resetMultipleAnalysisData(tabId);
			}, 3000);
		}
	};

	useEffect(() => {
		if (context.rightPanel === RightPanel.Multiple && context.multipleLocationsAnalysed && context.jobId) {
			getMultipleAnalysisData();
		}
	}, [context.jobId]);

	useEffect(() => {
		if (context.rightPanel === RightPanel.Multiple && context.multipleLocationsAnalysed) {
			if (!context.hazardMultipleAnalysedLegends || context.hazardMultipleAnalysedLegends.length === 0) {
				setOption(Option.CLIMATE);
				dispatch({ type: AppAction.setOption, payload: Option.CLIMATE });
			} else if (!context.climateMultipleAnalysedLegends || context.climateMultipleAnalysedLegends.length === 0) {
				setOption(Option.HAZARD);
				dispatch({ type: AppAction.setOption, payload: Option.HAZARD });
			}
		}
	}, [context.hazardMultipleAnalysedLegends, context.climateMultipleAnalysedLegends]);

	const showInfo = () => {
		dispatch({ type: AppAction.setShowLocationAnalysisDetails, payload: true });
	};

	return (
		<div className="m-3">
			{context.rightPanel === RightPanel.Single && context.singleLocationAnalysed && (
				<div>
					<div className="mb-8">
						<Options
							layerLength={!context.analysisData || typeof context.analysisData!.legendItems === "string" ? 0 : context.analysisData!.legendItems.length}
							selected={option}
							onChange={selectOption}
						/>
					</div>
					{option === Option.HAZARD && (
						<div>
							<div className={styles.singleHazardInfo}>
								<div
									className="tooltip tooltip-left z-50 w-[35px] h-[24px]"
									data-tip={t("results.singleIntensityTip")}
								></div>
							</div>
							<div className={styles.resultsSingle}>
								<AnalysisResults />
							</div>
						</div>
					)}
					{option === Option.CLIMATE && (
						<div className={styles.resultsSingle}>
							<ClimateResults />
						</div>
					)}
				</div>
			)}

			{context.rightPanel === RightPanel.Multiple && context.multipleLocationsAnalysed && (
				<div>
					<div className="mb-4">
						<Options
							selected={option}
							onChange={selectOption}
						/>
					</div>
					<div className={styles.locationInfo}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div>
								{getCheckedCounts(context.currentMultipleLocations?.items)}/{context.currentMultipleLocations?.itemCount} {t("multiple.locations")} {t("results.multipleSelected")}
							</div>
							<Icon
								icon="info"
								onClick={showInfo}
							></Icon>
						</div>
					</div>
					<div className={styles.resultsMultiple}>
						{option === Option.HAZARD && <HazardMultipleResults />}
						{option === Option.CLIMATE && <ClimateMultipleResults />}
					</div>
				</div>
			)}
		</div>
	);
}

export default Results;
