import { addLocationsets } from "api/layer";
import { ReactComponent as ExcelSvg } from "assets/icon_excel.svg";
import Button from "components/button/button";
import Modal from "components/modal/modal";
import { AppAction, AppContext } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
// import { v4 as uuidv4 } from "uuid";
import { getLocationsetsItems } from "api/layer";
import styles from "./import-modal.module.scss";

function ImportModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const [loading, setLoading] = useState(false);

	const close = (): void => {
		dispatch({ type: AppAction.setImportModal, payload: false });
	};

	const hanldeChangeUploadFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setLoading(true);
		const locationSource = context.locationSource;
		if (!locationSource) {
			return;
		}
		console.log(locationSource);
		//latlng address

		// if (!context.errorModal) {
		// 	showErrorModal();
		// 	return;
		// }

		const file = event.target.files?.[0];
		if (!file) {
			return;
		}
		const reader = new FileReader();

		const errorData: { row: number; message: string }[] = [];

		reader.onloadend = (progressEvent) => {
			const arrayBuffer = reader.result;
			const options: XLSX.ParsingOptions = { type: "array" };
			const workbook = XLSX.read(arrayBuffer, options);
			const sheetName = workbook.SheetNames;
			const sheet = workbook.Sheets[sheetName[0]];
			const sheet1 = workbook.Sheets[sheetName[1]];
			const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
			const jsonData1 = XLSX.utils.sheet_to_json(sheet1);
			console.log(jsonData, jsonData1);

			if (!jsonData || jsonData.length === 0) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "emptyTitle",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "emptyText",
					},
				});
				setLoading(false);
				close();
				return;
			}

			const header = jsonData[0];
			if (!header || !Array.isArray(header) || header.length === 0) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "emptyTitle",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "emptyText",
					},
				});
				setLoading(false);
				close();
				return;
			}

			const name = header[1];
			if (!name) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "emptyTitle",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "emptyText",
					},
				});
				setLoading(false);
				close();
				return;
			}

			if (!Array.isArray(jsonData[0]) || jsonData[0].length === 0) {
				dispatch({
					type: AppAction.setSnackBarParams,
					payload: {
						title: "emptyTitle",
						// text: "ccc",
						titleIconColor: "yellow",
						titleIcon: "warning",
						message: "emptyText",
					},
				});
				setLoading(false);
				close();
				return;
			}

			// const newUUID = uuidv4();

			const items = jsonData1.map((e: any, i) => {
				const row = i + 2;

				const id = e[t("importLocationSet.id")];
				const name = e[t("importLocationSet.name")];

				if (!id) {
					errorData.push({
						row,
						message: t("importErrorMessage.idError"),
					});
				}

				if (!name) {
					errorData.push({
						row,
						message: t("importErrorMessage.nameError"),
					});
				}

				const latitude = e[t("importLocationSet.latitude")];
				const longitude = e[t("importLocationSet.longitude")];

				const country = e[t("importLocationSet.country")];

				const street = e[t("importLocationSet.street")];
				const place = e[t("importLocationSet.place")];
				const province = e[t("importLocationSet.province")];
				const zipCode = e[t("importLocationSet.zipCode")] + "";

				if (locationSource === "latlng") {
					if (!latitude) {
						errorData.push({
							row,
							message: t("importErrorMessage.latitudeError"),
						});
					}

					if (!longitude) {
						errorData.push({
							row,
							message: t("importErrorMessage.longitudeError"),
						});
					}

					if (latitude < -90 || latitude > 90) {
						errorData.push({
							row,
							message: t("importErrorMessage.latitudeRangeError"),
						});
					}

					if (longitude < -180 || longitude > 180) {
						errorData.push({
							row,
							message: t("importErrorMessage.longitudeRangeError"),
						});
					}
				} else if (locationSource === "address") {
					if (!country) {
						errorData.push({
							row,
							message: t("importErrorMessage.countryError"),
						});
					}

					if (!street && !place && !province && !zipCode) {
						errorData.push({
							row,
							message: t("importErrorMessage.addressError"),
						});
					}
				}

				return {
					code: new Date().getTime() + i + "",
					// createTime: new Date().toISOString(),
					// locationSetId: newUUID.toString(),
					coordinateSource: e[t("importLocationSet.coordinateSource")],
					country,
					countryISO3: e[t("importLocationSet.countryISO3")],
					county: e[t("importLocationSet.county")],
					id,
					latitude,
					longitude,
					name,
					number: e[t("importLocationSet.number")] + "",
					place,
					province,
					street,
					zipCode,
				};
			});

			let params = {
				// id: newUUID.toString(),
				name,
				items,
			};

			if (errorData.length > 0) {
				dispatch({ type: AppAction.setErrorData, payload: errorData });
				setLoading(false);
				showErrorModal();
				return;
			}

			addLocationsets(params).then(async (result) => {
				if (result.status) {
					const locationSetId = result.data.id;

					getLocationsetsItems(locationSetId).then(async (res) => {
						dispatch({ type: AppAction.setCurrentMultipleLocations, payload: res.data });
						dispatch({ type: AppAction.setEditModal, payload: true });

						setLoading(false);
						close();
					});
				}
			});
		};

		reader.readAsArrayBuffer(file);
	};

	const showErrorModal = (): void => {
		dispatch({ type: AppAction.setErrorModal, payload: true });
	};

	return (
		<Modal
			header={t("locations.modal.title")}
			opened={context.importModal}
			onClose={close}
		>
			<div className={styles.modal}>
				<input
					className={styles.uploadIpt}
					onClick={(e) => e.stopPropagation()}
					onChange={hanldeChangeUploadFile}
					type="file"
				/>
				<div className={styles.import}>
					<div className={styles.upload}>
						<ExcelSvg className={styles.icon} />
						<div className={styles.text1}>{t("locations.modal.text1")}</div>
						<div className={styles.text2}>
							<div>{t("locations.modal.text2")}</div>
							&nbsp;
							<div style={{ color: "green" }}>{t("locations.modal.text3")}</div>
							&nbsp;
							<div>{t("locations.modal.text4")}</div>
							&nbsp;
						</div>
					</div>
					{loading && (
						<div
							className="loading loading-spinner"
							style={{ color: "#666" }}
						></div>
					)}
					<div className={styles.hint}>{t("locations.modal.hint")}</div>
					{/* href={context.language === 'en' ? "/location-set-import-template.xlsx" : "/地点集导入模版.xlsx"} */}
					<a
						className={styles.link}
						href={`${process.env.PUBLIC_URL}/${context.language === "en" ? "location-set-import-template.xlsx" : "地点集导入模版.xlsx"}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{t("locations.modal.link")}
					</a>
				</div>
			</div>
			<div className={styles.buttons}>
				<Button onClick={close}>{t("locations.modal.cancel")}</Button>
			</div>
		</Modal>
	);
}

export default ImportModal;
