import { AppContext } from "helpers/context";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Chart from "components/chart/chart"
import Svg from "components/svg/svg";
import styles from "./hazard-multiple-results.module.scss";
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactComponent as NotAnalysisSvg } from "assets/notAnalysis.svg";
import LinearProgress from '@mui/material/LinearProgress';

export default function HazardMultipleResults() {
    const [context, dispatch] = useContext(AppContext);
    const [t] = useTranslation();
    const [activeLegend, setActiveLegend] = useState(0);
    const [chartOption, setChartOption] = useState<any>(undefined);
    const [chartHeight, setChartHeight] = useState(0);
    const [tableData, setTableData] = useState<any[]>([]);
    const [noData, setNoData] = useState<any[]>([]);
    // const [analysisResultData, setAnalysisResultData] = useState<any[]>([]);

    const handleLegendClick = (index: number) => {
        setActiveLegend(index)
    }

    const toFixedTwo = (num: number) => {
        return num % 1 === 0 ? num : num.toFixed(2);
    }

    const noDataText = () => {
        if (noData.length > 0) {
            let text = t("multiple.noData")
            noData.forEach((item: any, index: number) => {
                if (index === 0) {
                    text += t(`layerId.${item.layerId}`)
                } else if (index === noData.length - 1) {
                    text += t("multiple.and") + t(`layerId.${item.layerId}`)
                } else {
                    text += t("multiple.comma") + t(`layerId.${item.layerId}`)
                }
            })
            return text + t("multiple.period");
        }
    }

    useEffect(() => {
        if (context.hazardMultipleAnalysisResult && (context.hazardMultipleAnalysisResult.length > 0)
            && context.hazardMultipleAnalysedLegends && (context.hazardMultipleAnalysedLegends.length > 0)) {
            setActiveLegend(0)
            let resultData = context.hazardMultipleAnalysisResult.filter((item: any) => {
                let isFind = context.hazardMultipleAnalysedLegends!.find((legend: any) => {
                    return legend.layerId === item.layerId;
                });
                return isFind !== undefined;
            });
            // setAnalysisResultData(resultData)
            let arr: any = [...resultData].sort((a: any, b: any) => {
                return b.intensity - a.intensity;
            });

            let data: any = [];

            context.hazardMultipleAnalysedLegends.forEach((legend, index) => {
                arr.forEach((item: any) => {
                    if (item.layerId === legend.layerId) {
                        if (item.valueLabel === "Outside" || item.valueLabel === "No Data" || item.valueLabel === "No Observation") {
                            if (data[index]) {
                                data[index].push({
                                    ...legend,
                                    ...item,
                                    value: 1,
                                    itemStyle: {
                                        color: "grey",
                                        opacity: "0.5",
                                    }
                                })
                            } else {
                                data[index] = [{
                                    ...legend,
                                    ...item,
                                    value: 1,
                                    itemStyle: {
                                        color: "grey",
                                        opacity: "0.5",
                                    }
                                }]
                            }
                        } else {
                            legend.rules.forEach((rules: any) => {
                                if (item.valueLabel === rules.title) {
                                    const raster = rules.symbolizers[0]?.raster?.color;
                                    const polygon = rules.symbolizers[0]?.polygon?.fill;
                                    const rasterOpacity = rules.symbolizers[0]?.raster?.opacity;
                                    const polygonOpacity = rules.symbolizers[0]?.polygon?.fillOpacity;
                                    const color = raster || polygon;
                                    const opacity = rasterOpacity || polygonOpacity;
                                    if (data[index]) {
                                        data[index].push({
                                            ...legend,
                                            ...item,
                                            value: 1,
                                            itemStyle: {
                                                color: color,
                                                opacity: opacity,
                                            }
                                        })
                                    } else {
                                        data[index] = [{
                                            ...legend,
                                            ...item,
                                            value: 1,
                                            itemStyle: {
                                                color: color,
                                                opacity: opacity,
                                            }
                                        }]
                                    }
                                }
                            })
                        }
                    }
                })
            });

            let tableData: any = [],
                noData: any = [];

            data.forEach((dataItem: any) => {
                const valueMap: any = {};
                if (dataItem && dataItem.length > 0) {
                    dataItem.forEach((item: any) => {
                        const label = item.valueLabel;
                        if (valueMap[label] === undefined) {
                            valueMap[label] = 1; // First occurrence
                            item.value = dataItem.filter((i: any) => i.valueLabel === label).length; // Count total occurrences
                        } else {
                            item.value = 0; // Subsequent occurrences
                        }
                    });

                    if ((dataItem[0].valueLabel === "Outside" || dataItem[0].valueLabel === "No Data" || dataItem[0].valueLabel === "No Observation") && (dataItem[0].value === dataItem.length)) {
                        noData.push(dataItem[0])
                    } else {
                        tableData.push(dataItem)
                    }
                }

            });

            setTableData(tableData)
            setNoData(noData)

            let series: any = [];
            let Xnum = tableData[0] ? tableData[0].length : 0;
            let Ynum = tableData.length;
            setChartHeight(Ynum)
            for (let i = 0; i < Xnum; i++) {
                let dataArr = [];
                for (let j = 0; j < Ynum; j++) {
                    if (tableData[j]) {
                        dataArr.unshift(tableData[j][i]);
                    }
                }
                series.push({
                    name: i,
                    type: 'bar',
                    stack: 'total',
                    barWidth: 24,
                    // emphasis: {
                    //     focus: 'self'
                    // },
                    data: dataArr
                })
            }

            const option: any = {
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                    backgroundColor: "rgba(0,0,0,0.6)",
                    borderColor: "rgba(0,0,0,0.6)",
                    formatter: function (params: any, ticket: any) {
                        return `<div>
                            <p style="color: #fff;font-size:14px;">${t("legend." + params.data.valueLabel)}</p>    
                            <p style="color: #fff;font-size:14px;margin: 5px 0;">${t("layerId." + params.data.layerId)}</p>    
                            <p style="display: flex;justify-content: space-between;align-items: center;font-size: 12px;"><span style="color: rgba(255,255,255,0.8);margin-right:10px;">${t("locations.table.locations")}</span> <span style="color: #fff">${params.data.value}</span></p>    
                            <p style="display: flex;justify-content: space-between;align-items: center;font-size: 12px;"><span style="color: rgba(255,255,255,0.8);margin-right:10px;">${t("locations.shareOfNumberOfLocations")}</span> <span style="color: #fff">${toFixedTwo((params.data.value / Xnum) * 100)}%</span></p>    
                        </div>`;
                    },
                },
                grid: {
                    left: 10,
                    right: 10,
                    bottom: 10,
                    top: 10,
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    axisLine: {
                        show: true
                    },
                    minInterval: 1
                },
                yAxis: {
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: true,
                        onZero: true,
                        lineStyle: {
                            color: "#fff",
                            shadowOffsetX: -1,
                            shadowColor: "#333"
                        }

                    },
                },
                series: series
            };

            setChartOption(option)
        }
    }, [context.hazardMultipleAnalysisResult, context.hazardMultipleAnalysedLegends])

    if (context.hazardMultipleAnalysisStatus && context.hazardMultipleAnalysisStatus === "error") {
        return (
            <div className="h-full flex flex-col items-center justify-center">
                <div className="text-center" style={{ color: "gray" }}><span className="material-icons">error_outline</span></div>
                <div className="text-[14px]">{t("single.graunchTitle")}</div>
                <div dangerouslySetInnerHTML={{ __html: t("single.graunchContent") }}></div>
            </div>);
    }

    if (!context.hazardMultipleAnalysedLegends || context.hazardMultipleAnalysedLegends.length === 0) {
        return (
            <div className="h-full flex flex-col items-center justify-center">
                <NotAnalysisSvg className="w-[60px] h-[60px] mx-auto mb-4" />
                <div style={{ color: "var(--gray-text)" }}>{t("single.notMultipleAnalysis")}</div>
            </div>);
    }

    if (context.hazardMultipleAnalysisStatus && context.hazardMultipleAnalysisStatus === "running") {
        return (
            <div className="h-full flex flex-col items-center justify-center">
                <LinearProgress color="success" style={{ width: "40%", marginBottom: "10px" }} />
                <div>{t("multiple.alalyseLoadingContent")}</div>
            </div>);
    }

    return (<>
        {tableData.length > 0 &&
            (<div className={styles.chart} style={{ height: chartHeight * 63 + "px" }}>
                <div className={styles.legendIcon}>
                    {tableData.map((tableDataItem: any, index: number) => {
                        return (
                            <IconButton
                                key={index}
                                onClick={() => { handleLegendClick(index) }}
                                className={`${activeLegend === index ? styles.activeLegend : ""}`}
                            >
                                {Svg({ icon: tableDataItem[0].layerId })()}
                            </IconButton>)
                    })}
                </div>
                <Chart option={chartOption} />
            </div>)}
        {noData.length > 0 && <div className="m-2" >{noDataText()}</div>}
        {(tableData[activeLegend] || noData.length > 0) && (
            <div>
                <p className="m-3">{t(`layerId.${tableData[activeLegend] ? tableData[activeLegend][0].layerId : noData[0].layerId}`)}</p>
                <Paper sx={{ width: '100%', boxShadow: 'none' }} className={styles.table}>
                    <TableContainer sx={{ height: '100%' }}>
                        <Table stickyHeader aria-label="sticky table" size="small">
                            <TableHead>
                                <TableRow className={styles.row}>
                                    <TableCell
                                        align="left"
                                    >{tableData[activeLegend] ? tableData[activeLegend][0].subtitle : noData[0].subtitle}</TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        {t("locations.table.locations")}
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                    >
                                        {t("locations.table.share")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData[activeLegend] && tableData[activeLegend].map((item: any, index: number) => (
                                    item.value > 0 && <TableRow
                                        key={index}
                                    >
                                        <TableCell
                                            align="left">
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ flexShrink: 0, width: "16px", height: "16px", marginRight: "5px", display: 'inline-block', backgroundColor: item.itemStyle.color, opacity: item.itemStyle.opacity }}></span>
                                                {item.valueLabel}
                                            </div>
                                        </TableCell>
                                        <TableCell align="right">{item.value}</TableCell>
                                        <TableCell align="right">{toFixedTwo(item.value / (tableData[activeLegend].length) * 100)}%</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow style={{ fontWeight: 'bold', height: "50px" }}>
                                    <TableCell >{t("multiple.total")}</TableCell>
                                    <TableCell align="right">{tableData[activeLegend] ? tableData[activeLegend].length : 0}</TableCell>
                                    <TableCell align="right">100%</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        )}

    </>);
}
