import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { addLocationSetItems, patchLocationsetsItems } from "api/layer";
import Icon from "components/icon/icon";
import { LeftPanel } from "helpers/constants";
import { AppAction, AppContext, setLeftPanel } from "helpers/context";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./add-location.module.scss";
import Modal from "components/modal/modal";

export default function AddLocation() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();
	const options = context.multipleLocations || [];
	const locationSetId: string = context.currentMultipleLocations ? context.currentMultipleLocations.id : "";
	let LocationData = {
		locationSetId,
		code: "",
		name: "",
		country: "",
		county: "",
		longitude: "",
		latitude: "",
		number: "",
		place: "",
		province: "",
		street: "",
		zipCode: "",
	};

	if (context.leftPanel === LeftPanel.AddLocation && context.searchLocationInfo) {
		const locationInfo = context.searchLocationInfo[context.language];

		const country = typeof locationInfo.country === "string" ? locationInfo.country : "";
		const county = typeof locationInfo.district === "string" ? locationInfo.district : "";
		const longitude = locationInfo.location.split(",")[0];
		const latitude = locationInfo.location.split(",")[1];
		const number = locationInfo.streetNumber && typeof locationInfo.streetNumber.number === "string" ? locationInfo.streetNumber.number : "";
		const place = typeof locationInfo.formatted_address === "string" ? locationInfo.formatted_address : "";
		const province = typeof locationInfo.province === "string" ? locationInfo.province : "";
		const street = locationInfo.streetNumber && typeof locationInfo.streetNumber.street === "string" ? locationInfo.streetNumber.street : "";
		const zipCode = typeof locationInfo.adcode === "string" ? locationInfo.adcode : "";

		LocationData = {
			locationSetId,
			code: "",
			name: "",
			country,
			county,
			longitude,
			latitude,
			number,
			place,
			province,
			street,
			zipCode,
		};
	} else {
		LocationData = { ...context.editMultipleLocation, locationSetId };
	}

	const [Location, setLocation] = useState(LocationData);
	const [disabled, setDisabled] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isChange, setIsChange] = useState(false);
	const [isClose, setIsClose] = useState(false);

	const save = async () => {
		setDisabled(true);
		if (!(Location.locationSetId && Location.code && Location.name && Location.longitude && Location.latitude)) {
			setDisabled(false);
			setIsError(true);
			return;
		}
		setIsError(false);
		if (context.leftPanel === LeftPanel.AddLocation) {
			try {
				let res = await addLocationSetItems(context.currentMultipleLocations!.id, Location);
				context.multipleLocations?.forEach((item) => {
					if (item.id === res.data.locationSetId) {
						if (item.items === null) {
							item.items = [res.data];
							context.currentMultipleLocations!.items = [res.data];
						} else {
							item.items!.push(res.data);
							context.currentMultipleLocations!.items!.push(res.data)
						}
						if (!item.itemCount) {
							item.itemCount = 1
						} else {
							item.itemCount += 1
						}
					}
				});
				dispatch({ type: AppAction.setCurrentMultipleLocations, payload: context.currentMultipleLocations });
				dispatch({ type: AppAction.setMultipleLocations, payload: context.multipleLocations });
				dispatch({
					type: AppAction.setSnackBarParams, payload: {
						title: "createdLocationTitle",
						text: Location.name,
						message1: "createdLocationMessage1",
						message2: "createdLocationMessage2",
					}
				})
			} catch (error) {
				setDisabled(false);
			}
		} else {
			try {
				let res = await patchLocationsetsItems(context.currentMultipleLocations!.id, context.editMultipleLocation.id, Location);
				context.currentMultipleLocations!.items = context.currentMultipleLocations!.items?.map((item) => {
					if (item.id === context.editMultipleLocation.id) {
						item = { ...item, ...res.data };
					}
					return item;
				});
				let multipleLocations = context.multipleLocations?.map((item) => {
					if (item.id === context.currentMultipleLocations!.id) {
						item.items = context.currentMultipleLocations!.items;
					}
					return item;
				});

				dispatch({ type: AppAction.setCurrentMultipleLocations, payload: context.currentMultipleLocations });
				dispatch({ type: AppAction.setMultipleLocations, payload: multipleLocations });
				dispatch({
					type: AppAction.setSnackBarParams, payload: {
						title: "editLocationTitle",
						text: context.editMultipleLocation.name,
						message1: "editLocationMessage1",
						message2: "editLocationMessage2",
					}
				})
				dispatch({ type: AppAction.setEditMultipleLocation, payload: null });
			} catch (error) {
				setDisabled(false);
			}
		}
		setDisabled(false);
		setLeftPanel(dispatch, LeftPanel.Locations);
	};

	const handleChange = (event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = event.target;
		if (name === "locationSetId" && value === "") {
			dispatch({ type: AppAction.setCreateModal, payload: true });
			return
		}
		setIsChange(true);
		setLocation((prevData) => ({ ...prevData, [name]: value }));
	};

	const cancel = () => {
		if (context.leftPanel === LeftPanel.AddLocation) {
			close()
		} else {
			setIsClose(true)
		}
	};

	const close = () => {
		setLeftPanel(dispatch, LeftPanel.Locations);
		dispatch({ type: AppAction.setEditMultipleLocation, payload: null });
	}

	const handleDelete = () => {
		dispatch({
			type: AppAction.setDeleteLocationModal,
			payload: context.editMultipleLocation,
		});
	};

	const handleClose = () => {
		setIsClose(false);
	};

	return (
		<div className={styles.add}>
			<div className={styles.header}>
				<div className={styles.title}>
					<div className="flex items-center">
						<Icon
							icon="arrow_back"
							onClick={cancel}
						/>
						<span>{context.leftPanel === LeftPanel.EditLocation ? t("locations.add.editTitle") : t("locations.add.title")}</span>
					</div>
					<div>
						<Icon onClick={() => setLeftPanel(dispatch, LeftPanel.Empty)} />
					</div>
				</div>
			</div>
			<div className={styles.body}>
				<Stack spacing={{ xy: 1, sm: 2 }}>
					<FormControl fullWidth>
						<InputLabel
							required
							color="success"
						>
							{t("locations.add.Location_set")}
						</InputLabel>
						<Select
							required
							name="locationSetId"
							value={Location.locationSetId}
							disabled={context.leftPanel === LeftPanel.EditLocation}
							onChange={handleChange}
							error={isError && !Location.locationSetId}
							label={t("locations.add.Location_set")}
							color="success"
							MenuProps={{
								PaperProps: {
									style: {
										maxHeight: 350,
									}
								}
							}}
						>
							{options.map((option, index) => (
								<MenuItem
									key={index}
									value={option.id}
								>
									{option.name}({option.itemCount})
								</MenuItem>
							))}
							<MenuItem style={{ padding: 0, borderTop: '1px solid #0000001f', height: 45 }} value="">
								<Button
									variant="text"
									color="success"
									style={{ width: "100%", height: "100%" }}
								>
									{t("single.addNewLocationSet")}
								</Button>
							</MenuItem>
						</Select>
					</FormControl>
					<TextField
						required
						name="code"
						value={Location.code}
						onChange={handleChange}
						error={isError && !Location.code}
						label={t("locations.add.ID")}
						variant="outlined"
						color="success"
					/>
					<TextField
						required
						name="name"
						value={Location.name}
						onChange={handleChange}
						error={isError && !Location.name}
						label={t("locations.add.Name")}
						variant="outlined"
						color="success"
					/>
					<Stack
						spacing={{ xs: 1 }}
						direction="row"
						useFlexGap
						sx={{ flexWrap: "wrap" }}
					>
						<TextField
							required
							sx={{ flex: 1 }}
							name="longitude"
							value={Location.longitude}
							onChange={handleChange}
							error={isError && !Location.longitude}
							label={t("locations.add.Longitude")}
							variant="outlined"
							color="success"
						/>
						<TextField
							required
							sx={{ flex: 1 }}
							name="latitude"
							value={Location.latitude}
							onChange={handleChange}
							error={isError && !Location.latitude}
							label={t("locations.add.Latitude")}
							variant="outlined"
							color="success"
						/>
					</Stack>

					<TextField
						// required
						name="zipCode"
						value={Location.zipCode}
						onChange={handleChange}
						label={t("locations.add.zipCode")}
						variant="outlined"
						color="success"
					/>
					<Stack
						spacing={{ xs: 1 }}
						direction="row"
						useFlexGap
						sx={{ flexWrap: "wrap" }}
					>
						<TextField
							// required
							sx={{ flex: 1 }}
							name="street"
							value={Location.street}
							onChange={handleChange}
							label={t("locations.add.streetName")}
							variant="outlined"
							color="success"
						/>
						<TextField
							// required
							sx={{ flex: 1 }}
							name="number"
							value={Location.number}
							onChange={handleChange}
							label={t("locations.add.streetNumber")}
							variant="outlined"
							color="success"
						/>
					</Stack>
					<TextField
						// required
						name="place"
						value={Location.place}
						onChange={handleChange}
						label={t("locations.add.place")}
						variant="outlined"
						color="success"
					/>
					<TextField
						// required
						name="county"
						value={Location.county}
						onChange={handleChange}
						label={t("locations.add.county")}
						variant="outlined"
						color="success"
					/>
					<TextField
						// required
						name="province"
						value={Location.province}
						onChange={handleChange}
						label={t("locations.add.province")}
						variant="outlined"
						color="success"
					/>
					<TextField
						// required
						name="country"
						value={Location.country}
						onChange={handleChange}
						label={t("locations.add.country")}
						variant="outlined"
						color="success"
					/>
				</Stack>
			</div>
			<div className={styles.footer}>
				<div>
					<Button
						type="submit"
						variant="contained"
						disabled={disabled}
						onClick={save}
						color="success"
					>
						{t("locations.add.save")}
					</Button>
					{isChange &&
						<Button
							variant="text"
							onClick={cancel}
							color="success"
							style={{ marginLeft: "10px" }}
						>
							{t("locations.add.cancel")}
						</Button>}
				</div>
				{context.leftPanel === LeftPanel.EditLocation &&
					<Button
						variant="text"
						onClick={handleDelete}
						color="error"
					>
						{t("locations.add.delete")}
					</Button>}
			</div>

			<Modal
				header={t("locations.add.leaveTitle")}
				opened={isClose}
				onClose={handleClose}
			>
				<div className={styles.info}>
					<div className={styles.text}>{t("locations.add.leaveMessage")}</div>

					<div className={styles.button}>
						<Button
							variant="text"
							color="success"
							onClick={handleClose}
							style={{ marginRight: "10px" }}
						>
							{t("locations.add.cancel")}
						</Button>
						<Button
							variant="outlined"
							color="success"
							onClick={close}
							style={{ marginRight: "10px" }}
						>
							{t("locations.add.leave")}
						</Button>
						<Button
							variant="contained"
							type="submit"
							disabled={disabled}
							onClick={save}
							color="success"
						>
							{t("locations.add.save")}
						</Button>
					</div>
				</div>
			</Modal>
		</div >
	);
}
