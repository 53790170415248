import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { getLocationsetsItems, patchLocationsets } from "api/layer";
import Button from "components/button/button";
import Modal from "components/modal/modal";
import { AppAction, AppContext, getMultipleLocations } from "helpers/context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./edit-modal.module.scss";

function EditModal() {
	const [context, dispatch] = useContext(AppContext);
	const [t] = useTranslation();

	const currentMultipleLocations = context.currentMultipleLocations;
	const [inputValue, setInputValue] = useState(currentMultipleLocations?.name);

	const close = (): void => {
		dispatch({ type: AppAction.setEditModal, payload: undefined });
	};

	const save = () => {
		if (!inputValue) {
			return;
		}
		if (!currentMultipleLocations) {
			return;
		}
		if (!currentMultipleLocations.id) {
			return;
		}
		// if (currentMultipleLocations.name === inputValue) {
		// 	return;
		// }

		const locationSetId = currentMultipleLocations.id;

		patchLocationsets(locationSetId, { name: inputValue }).then((res) => {
			// dispatch({ type: AppAction.setCurrentMultipleLocations, payload: { ...context.currentMultipleLocations, ...currentMultipleLocations } });
			close();
			getLocationsetsItems(locationSetId).then(async (res) => {
				dispatch({ type: AppAction.setCurrentMultipleLocations, payload: res.data });
				// dispatch({ type: AppAction.setEditModal, payload: true });

				getMultipleLocations(dispatch).then(() => {
					dispatch({ type: AppAction.setSingleLocation, payload: undefined });
					dispatch({ type: AppAction.setSingleLocationAnalysed, payload: undefined });
					// close();
				});
			});
		});
	};

	useEffect(() => {
		setInputValue(context.currentMultipleLocations?.name);
	}, [context]);

	return (
		<Modal
			header={t("locations.editModal.title")}
			opened={!!context.editModal}
			onClose={close}
		>
			<div className={styles.modal}>
				<Stack
					spacing={3}
					sx={{ width: 300 }}
				>
					<TextField
						required
						error={inputValue === "" || inputValue === undefined}
						id="outlined-required"
						label={t("locations.editModal.name")}
						// defaultValue={currentMultipleLocations?.name}
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						color="success"
						helperText={inputValue === "" || inputValue === undefined ? t("locations.editModal.nameText") : ""}
					/>
				</Stack>
			</div>
			<div className={styles.buttons}>
				<Button
					onClick={close}
					type="secondary"
				>
					{t("locations.editModal.cancel")}
				</Button>
				&nbsp;&nbsp;
				<Button onClick={save}>{t("locations.editModal.create")}</Button>
			</div>
		</Modal>
	);
}

export default EditModal;
